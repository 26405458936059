html,body {
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: Montserrat,sans-serif;
    font-size: 1rem;
}

.px-user-widget {
    width: 100%;
}

form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form-group {
    width: 80%;
    display: flex;
    flex-direction: column-reverse;
    margin: 1rem;
}

.form-group .control-label {
    margin-top: .5rem;
    margin-bottom: .5rem;
    margin-left: .5rem;
}

.form-group input {
    font-family: Montserrat,sans-serif;
    display: block;
    width: 100%;
    padding: .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    border-radius: 1rem;
}

.form-group input.error {
    border-color: #e54040;
}

.form-group input.success {
    border-color: #4caf50;
}

.form-group input:focus-visible {
    outline: none;
}

.btn {
    font-family: Montserrat,sans-serif;
    border: none;
    outline: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    font-weight: 400;
    line-height: 2.25rem;
    text-align: center;
    text-decoration: none;
    padding: 0 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1rem;
    border-radius: 1rem;
    min-width: 8rem;
}

li.password-rules__message {
    margin-left: -20px;
    margin-right: 10px;
    list-style: none;
    padding-left: 1.563rem;
    background-repeat: no-repeat;
    background-position: left;
    background-size: 1rem;
}

li.password-rules__message.success {
    color: #4caf50;
}

li.password-rules__message.error {
    color: #e54040;
}

.form-group .control-label {
    color: #000000de;
}

.form-group input {
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    border: 1px solid rgba(0,0,0,.1215686275);
    color: #000000de;
}

.form-group input:-internal-autofill-selected {
    background-color: #fff;
}

.btn {
    transform: translateZ(0);
    transition: background .4s cubic-bezier(.25,.8,.25,1),box-shadow .28s cubic-bezier(.4,0,.2,1);
    color: #000000de;
}

.btn-primary {
    color: #fff;
    background-color: #179fdb;
}

.btn-primary:disabled,.btn-primary[disabled] {
    background-color: #6c757d;
    color: #a5a5a5;
}

.eip-login {
    width: 80%;
    margin: 0 auto;
}

.eip-login .form-group {
    width: 100%;
    margin-left: 0;
}

.eip-login .eip-login-separator {
    width: 50%;
    margin: 3rem auto;
    border: 1px solid #ddd;
}

.eip-login .eip-login-continue-btn {
    display: block;
    margin: 0 auto;
}

.eip-login-header {
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    font-weight: 300;
    line-height: .875rem;
    font-family: Montserrat,sans-serif;
    font-size: 1.2rem;
}
